<template>
  <div>
    <!-- 弹窗form -->
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false"
    >
      <!-- 表单 -->
      <DrawerComponent
        :button="drawerButton"
        @colse="formShow = false"
        :loading="loading"
        :drawerConfig="drawerConfig"
        @save="requestSave"
      >
        <!-- <div class="drawer-content-item">
          <div class="slot-body1">
            <div>
              <span class="label">要货计划总金额:</span>
              <el-input disabled v-model="this.drawerConfig[0].ruleForm.totalMoney" />
            </div>
            <div>
              <span class="label">冻结总金额:</span>
              <el-input disabled v-model="this.drawerConfig[0].ruleForm.freezeTotalMoney" />
            </div>
          </div>
        </div> -->
      </DrawerComponent>
      <!-- 弹窗商品列表 -->
      <div v-if="drawerShowProduct">
        <el-drawer
          custom-class="form_drawer"
          title="添加商品"
          size="80%"
          :visible.sync="drawerShowProduct"
          :modal="false"
          :append-to-body="true"
        >
          <div class="drawer-table">
            <TableComponent
              @searchData="searchProduct"
              :pagination="tablePaginationProduct"
              :templateData="tableDataProduct"
              :table-loading="tableLoadingProduct"
              :searchConfig="isCust?searchConfigProduct1:searchConfigProduct"
              :table-config="isCust?tableConfigProduct1:tableConfigProduct"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent
              @clickEvent="beforeSaveSelect"
              :btnType="'primary'"
              :icon="'el-icon-check'"
              >保存
            </buttonComponent>
            <buttonComponent
              @clickEvent="drawerShowProduct = false"
              :btnType="'danger'"
              :icon="'el-icon-close'"
              >关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 客户列表 -->
      <div>
        <el-drawer
          custom-class="form_drawer"
          title="选择客户"
          size="80%"
          :visible.sync="drawerShowCustomer"
          :modal="false"
          :append-to-body="true"
        >
          <div class="drawer-table">
            <TableComponent
              @searchData="searchCustomer"
              :pagination="tablePaginationCustomer"
              :templateData="tableDataCustomer"
              :table-loading="tableLoadingCustomer"
              :searchConfig="searchConfigCustomer"
              :table-config="tableConfigCustomer"
            ></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent
              @clickEvent="beforeSaveSelect"
              :btnType="'primary'"
              :icon="'el-icon-check'"
              >保存
            </buttonComponent>
            <buttonComponent
              @clickEvent="drawerShowCustomer = false"
              :btnType="'danger'"
              :icon="'el-icon-close'"
              >关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
    </el-drawer>

    <!-- 兼容商品的审批流 bpm待办事项引入组件查看详情-->
    <DrawerComponent
      v-if="dmsId"
      :button="drawerButton"
      @colse="formShow = false"
      :loading="loading"
      :drawerConfig="drawerConfig"
      @save="requestSave"
    ></DrawerComponent>
  </div>
</template>

<script>
import { log } from 'mathjs';
import buttonComponent from '../../../../compoment/button-component/button-component';
import TableComponent from '../../../../compoment/table-component/table-component.vue';
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  props: ['dmsId', 'dmsCode'],
  data() {
    return {
      dateRule: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      id: null,
      loading: false,
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: (data) => {
              this.requestSave(data, 'Y');
            },
          },
        ],
      },
      drawerConfig: [
        // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: { temporaryFlag: 'N' },
          formConfig: {
            // 弹窗表单配置
            items: [
              [
                {
                  label: '要货计划编号',
                  key: 'planCode',
                  show: false,
                },
                {
                  label: '公司主体',
                  key: 'companyCode',
                  valueType: 'select',
                  path: 'companyPageList',
                  options: {
                    label: 'companyName',
                    value: 'companyCode',
                  },
                  fn: (value, formData, ref) => {
                    this.$nextTick(() => {
                      this.setFormData(ref[0].selectedLabel, 'companyName');
                      this.deleteProduct('all', 1);
                    });
                  },
                },
                {
                  label: '年月',
                  key: 'yearMonthDate',
                  valueType: 'month',

                  pickerOptions: {
                    // 年月份选择条件
                    disabledDate: (time) => this.formartDisabledDate(time),
                    // const newDay = new Date();
                    // // 16-22日
                    // if (newDay.getDate() >= 16 && newDay.getDate() <= 22 && time.getMonth() - 2 === newDay.getMonth()) {
                    // if (time.getFullYear() === newDay.getFullYear()) {
                    // return false;
                    // }
                    // // 10-15日
                    // } else if (newDay.getDate() >= 10 && newDay.getDate() <= 25 && time.getMonth() - 1 === newDay.getMonth()) {
                    // if (time.getFullYear() === newDay.getFullYear()) {
                    // return false;
                    // }
                    // // 1-4日
                    // } else if (newDay.getDate() >= 1 && newDay.getDate() <= 25 && time.getMonth() - 1 === newDay.getMonth()) {
                    // if (time.getFullYear() === newDay.getFullYear()) {
                    // return false;
                    // }
                    // }
                    // return true;
                  },
                },
                {
                  label: '客户名称',
                  key: 'custName',
                  valueType: 'iconInput',
                  icon: 'el-icon-circle-plus-outline',
                  fn: () => {
                    this.searchCustomer({});
                  },
                },
                {
                  label: '申报次数',
                  key: 'applyAccount',
                  valueType: 'select',
                  // path: 'code?dictTypeCode=declare_number',
                  data: [],
                },
                // {
                // label: '注释文字',
                // key: 'remarks',
                // valueType: 'textarea',
                // width: '100%',
                // },
                {
                  label: '附件',
                  key: 'attachmentList',
                  valueType: 'inputUpload',
                  itemWidth: '100%',
                },
              ],
            ],
          },
          rules: {
            companyCode: [
              { required: true, message: '请选择公司主体', trigger: 'change' },
            ],
            custName: [
              { required: true, message: '请选择客户', trigger: 'change' },
            ],
            year: [{ required: true, message: '请输入年', trigger: 'blur' }],
            month: [{ required: true, message: '请输入月', trigger: 'change' }],
            applyAccount: [
              { required: true, message: '请选择申报次数', trigger: 'change' },
            ],
          },
        },
        {
          title: '商品信息',
          type: 'table',
          tableConfig: {
            disabled: false,
            rows: [
              {
                label: '商品编码',
                prop: 'productCode',
              },
              {
                label: '商品名称',
                prop: 'productName',
              },
              {
                label: '规格',
                prop: 'productStandard',
              },
              {
                label: '香型',
                prop: 'fragranceType',
              },
              {
                label: '数量（盒）',
                prop: 'num',
                type: 'input',
                inputType: 'number',
                precision: 0,
                fn: (row) => {
                  this.calculateWeight(row);
                  this.$nextTick(() => {
                    const math = require('mathjs');
                    const o = math.number(
                      math.multiply(
                        math.bignumber(row.productPrice),
                        math.bignumber(row.num),
                      ),
                    );
                    // const m = math.number(
                    //   math.multiply(
                    //     math.bignumber(o),
                    //     math.bignumber(row.freezeScale / 100),
                    //   ),
                    // );
                    // this.$set(row, 'freezeMoney', m);
                    this.$set(row, 'productTotalPrice', o);
                    // this.totalAmount();
                  });
                },
              },
              {
                label: '净含量',
                prop: 'tonCoefficient',
              },
              {
                label: '单价（元）',
                prop: 'productPrice',
                type: 'input',
                inputType: 'number',
                precision: 2,
                fn: (row) => {
                  this.$nextTick(() => {
                    const math = require('mathjs');
                    // console.log(math.number(math.add(math.bignumber(0.1), math.bignumber(0.2))));
                    const o = math.number(
                      math.multiply(
                        math.bignumber(row.productPrice),
                        math.bignumber(row.num),
                      ),
                    );
                    // const m = math.number(
                    //   math.multiply(
                    //     math.bignumber(o),
                    //     math.bignumber(row.freezeScale / 100),
                    //   ),
                    // );
                    // this.$set(row, 'freezeMoney', m);
                    this.$set(row, 'productTotalPrice', o);
                    // this.totalAmount();
                  });
                },
              },
              {
                label: '发货千升',
                prop: 'productTon',
              },
              {
                label: '金额（元）',
                prop: 'productTotalPrice',
              },
              // {
              //   label: '冻结比例（%）',
              //   prop: 'freezeScale',
              // },
              // {
              //   label: '冻结金额',
              //   prop: 'freezeMoney',
              // },
              {
                label: '备注',
                prop: 'remarks',
                type: 'input',
              },
            ],
            rowButtons: {
              items: [
                {
                  type: 'default',
                  icon: 'el-icon-delete',
                  fn: (row, index) => {
                    this.deleteProduct(index, 1);
                  },
                },
              ],
            },
          },
          tableData: [],
          tableButtons: [
            {
              title: '添加商品',
              fn: () => {
                this.searchProduct({});
              },
            },
            {
              title: '清空',
              fn: () => {
                this.deleteProduct('all', 1);
              },
            },
          ],
        },
      ],

      // ----------------------- 弹窗 - 商品列表配置
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tableDataProduct: [],
      tableConfigProduct: {
        checkbox: true,
        checkboxFn: this.selectData,
        isSaveSelect: true,
        rows: [
          {
            label: '商品编码',
            prop: 'productCode',
          },
          {
            label: '商品名称',
            prop: 'productName',
          },
        ],
      }, // 列表配置
      tableConfigProduct1: {
        checkbox: true,
        checkboxFn: this.selectData,
        isSaveSelect: true,
        rows: [
          {
            label: '商品编码',
            prop: 'materialCode',
          },
          {
            label: '商品名称',
            prop: 'materialName',
          },
        ],
      }, // 列表配置
      searchConfigProduct: {
        options: [
          {
            valueName: 'productCode',
            placeholder: '请输入商品编号',
          },
          {
            valueName: 'productName',
            placeholder: '请输入商品名称',
          },
        ],
      },
      searchConfigProduct1: {
        options: [
          {
            valueName: 'materialCode',
            placeholder: '请输入商品编号',
          },
          {
            valueName: 'materialName',
            placeholder: '请输入商品名称',
          },
        ],
      },
      tablePaginationProduct: {},

      // ----------------------- 弹窗 - 客户列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tablePaginationCustomer: {},
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户编码',
            prop: 'customerCode',
          },
          {
            label: '客户类型',
            prop: 'customerTypeName',
          },
        ],
      },
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          },
          {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          },
          {
            valueName: 'customerType',
            placeholder: '请选择客户类型',
            valueType: 'select',
            path: 'code?dictTypeCode=cust_type',
          },
        ],
      },
      customerList: [],
      isCust: null,
    };
  },

  created() {
    this.getfieldType();
    this.initPageState();
    this.controlNumber();
    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    this.id = this.dmsId || this.id;
  },

  watch: {
    id(val) {
      if (val) {
        this.searchDetail();
      }
    },
  },

  methods: {
    /**
     * 获取特殊客户数据字典
     */
    async getfieldType() {
      const { result } = await this.$http.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'demandplan_cus',
      });
      console.log(result, 'result');
      this.customerList = result;
    },
    /**
     * 计算总金额
     * */
    totalAmount() {
      const { tableData } = this.drawerConfig[1];
      let totalMoney = 0;
      let freezeTotalMoney = 0;
      const math = require('mathjs');
      tableData.forEach((i) => {
        totalMoney = math.number(
          math.add(
            math.bignumber(totalMoney),
            math.bignumber(i.productTotalPrice),
          ),
        );
        freezeTotalMoney = math.number(
          math.add(
            math.bignumber(freezeTotalMoney),
            math.bignumber(i.freezeMoney),
          ),
        );
        // totalMoney += Number(i.productTotalPrice);
        // freezeTotalMoney += Number(i.freezeMoney);
      });
      this.setFormData(totalMoney, 'totalMoney');
      this.setFormData(freezeTotalMoney, 'freezeTotalMoney');
    },
    // 控制月份
    formartDisabledDate(time) {
      const newDay = new Date();
      const nowDay = newDay.getDate();
      const nowMonth = newDay.getMonth();
      const finddateRule = this.dateRule.find(
        (aa) => aa.start <= nowDay && aa.end >= nowDay,
      );
      if (finddateRule && finddateRule.month) {
        const addMonth = new Date().setMonth(
          nowMonth + Number(finddateRule.month),
        );
        const addMonth2 = new Date().setMonth(
          nowMonth + 1 + Number(finddateRule.month),
        );
        if (time.getFullYear() === new Date(addMonth).getFullYear()) {
          const neAddMonth2 = new Date(addMonth2).getMonth() === 0 ? 12 : new Date(addMonth2).getMonth();
          if (
            time.getMonth() >= new Date(addMonth).getMonth()
            && time.getMonth() < neAddMonth2
          ) {
            return false;
          }
        }
      }

      // for (const item of this.dateRule) {
      // // if (newDay.getDate() >= item.start && newDay.getDate() <= item.end && time.getMonth() - item.month === newDay.getMonth()) {
      // // // if (time.getFullYear() === newDay.getFullYear()) {
      // // return false;
      // // // }
      // // }
      // }
      // }
      return true;
    },

    // 控制申报次数
    async controlNumber() {
      const newDay = new Date();
      const { result } = await this.$http.post('/mdm/mdmdictdata/treeList', {
        dictTypeCode: 'declare_number',
      });
      console.log('result', result);
      const dateRule = [];
      result.forEach((val) => {
        console.log('---->', val.disabled, val.dictValue);
        const temp = val.dictValue.split(',');
        const param = {
          start: temp[0],
          end: temp[1],
          month: temp[2],
          dictValue: val.dictDesc,
          dictCode: val.dictCode,
        };
        dateRule.push(param);
      });
      console.log('------------', dateRule);

      for (const dic of dateRule) {
        if (
          dic.dictCode
          && newDay.getDate() >= dic.start
          && newDay.getDate() <= dic.end
        ) {
          dic.disabled = false;
        } else {
          dic.disabled = true;
        }
      }
      this.dateRule = dateRule;

      this.drawerConfig[0].formConfig.items.forEach((item) => {
        item.forEach((list) => {
          // dictValue-字典值,dictDesc-字典描述
          if (list.key === 'applyAccount') {
            const tempList = list;
            tempList.data = dateRule;
            return tempList;
          }
        });
      });
    },
    initPageState() {
      if (this.code === 'view') {
        this.setFormItemValue(['planCode', 'approveStatus'], 'show', true);
      } else {
        this.setFormItemValue(['planCode', 'approveStatus'], 'show', false);
      }
      if (this.code !== 'add') {
        // 非新增查询详情
        this.searchDetail();
      }
    },

    /**
     * 设置禁用
     * @param state {boolean}
     */
    setDisabled(state) {
      this.drawerConfig[0].formConfig.disabled = state;
      this.drawerConfig[1].tableConfig.disabled = state;
      this.drawerConfig[1].tableConfig.rowButtons.show = !state;
      this.drawerButton.show = !state;
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id) {
        const { result } = await this.$http.post('/dms/dms/demandplan/query', {
          id: this.id,
        });
        this.drawerConfig[0].ruleForm = { ...result };
        this.drawerConfig[1].tableData = result.detailList;
        this.setDisabled(this.code === 'view');
      }
    },

    /**
     * 查询客户（经销商）列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.tableLoadingCustomer = true;
      const params = { pageSize: 10, ...requestData };
      const {
        result: { data, count },
      } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/pageList',
        params,
      );
      this.tableDataCustomer = data;
      this.tableLoadingCustomer = false;
      this.tablePaginationCustomer.total = count;
      this.showDrawer('drawerShowCustomer');
    },

    /**
     * 查询商品列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData) {
      this.tableLoadingProduct = true;
      const { companyCode, custCode } = this.drawerConfig[0].ruleForm;
      const productCodes = this.drawerConfig[1].tableData.map(
        (i) => i.productCode,
      );
      this.isCust = ((this.customerList || []).find((v) => v.dictCode === custCode) || null);
      const params = {
        pageSize: 10,
        companyCode,
        custCode,
        orderSourceType: 'back',
        excludeProductCodeList: productCodes,
        // excludeTypeList: ['促销品'],
        ...requestData,
        queryType: '1',
      };
      if (this.isCust) {
        const {
          result: { data, count },
        } = await this.$http.post(
          '/mdm/mdmMaterialController/pageList',
          params,
        );
        let list = data;
        list = list.map((v) => {
          const obj = v;
          obj.productCode = v.materialCode;
          obj.productName = v.materialName;
          obj.spec = v.specification;
          obj.ext2 = v.ext2;
          obj.ext13 = ((v.capacity || '').split('m') || [])[0] || '';
          obj.productPrice = 0;
          obj.productTotalPrice = 0;
          obj.num = 0;
          return obj;
        });
        this.tableDataProduct = list || [];
        this.tablePaginationProduct.total = count;
      } else {
        const {
          result: { data, count },
        } = await this.$http.post(
          '/dms/dms/availableGoods/availableGoodsList',
          params,
        );
        this.tableDataProduct = data || [];
        this.tablePaginationProduct.total = count;
      }

      this.tableLoadingProduct = false;
      this.showDrawer('drawerShowProduct');
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      this.selectTableData = data;
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        // 客户
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }
      if (this.drawerShowProduct === true) {
        // 商品
        this.saveProduct();
        return true;
      }
    },

    /**
     * 保存客户
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].customerCode, 'custCode');
      this.setFormData(this.selectTableData[0].customerName, 'custName');
      this.setFormData(this.selectTableData[0].customerType, 'custType');
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存选中的商品
     */
    async saveProduct() {
      const params = { pageNum: 1, pageSize: 15, parameterCode: 'acctfreeze_ratio' };
      const {
        result: { data, count },
      } = await this.$http.post(
        '/mdm/mdmparametermanage/list',
        params,
      );
      const freezeScale = data[0].parameterValue;
      this.selectTableData.map((i) => {
        delete i.id;
        i.tonCoefficient = i.ext13;
        i.fragranceType = i.ext2;
        i.productStandard = i.spec;
        i.num = 0;
        i.productPrice = 0;
        i.productTotalPrice = 0;
        i.remarks = '';
        // i.freezeScale = freezeScale;
        return i;
      });
      const tableData = [
        ...this.drawerConfig[1].tableData,
        ...this.selectTableData,
      ];
      this.setDrawerValue('商品信息', 'tableData', tableData);
      this.closeDrawer('drawerShowProduct');
    },

    /**
     * 商品询价
     * @returns {Promise<void>}
     */
    async searchPrice(row) {
      const params = {
        priceTypeEnum: 'P002',
        customerCode: this.drawerConfig[0].ruleForm.custCode,
        customerType: this.drawerConfig[0].ruleForm.custType,
        num: row.num,
        companyCode: this.drawerConfig[0].ruleForm.companyCode,
        productCode: row.productCode,
      };
      const rowData = row;
      const { result } = await this.$http.post(
        '/mdm/mdmPriceSettingExtController/searchPrice',
        [params],
      );
      if (result[0].code === 500) {
        this.$message.error(result[0].message);
        return;
      }
      this.$nextTick(() => {
        this.$set(rowData, 'productPrice', result[0].price);
        this.$set(
          rowData,
          'productTotalPrice',
          rowData.productPrice * rowData.num,
        );
        this.calculateWeight(rowData);
      });
    },

    /**
     * 发货吨计算
     * @returns {Promise<void>}
     */
    calculateWeight(row) {
      // this.$set(row, 'productTon', (row.ext13 * row.num) / 1000000);
      this.$set(row, 'productTon', (row.tonCoefficient * row.num) / 1000000);
    },
    /**
     * 提交表单数据
     * @param data {Object}
     */
    async requestSave(data = this.drawerConfig[0].ruleForm, temporaryFlag) {
      data.detailList = this.drawerConfig[1].tableData;
      data.temporaryFlag = temporaryFlag || 'N'; // 是否暂存
      this.loading = true;
      const url = this.code === 'edit'
        ? '/dms/dms/demandplan/updateDemandPlan'
        : '/dms/dms/demandplan/saveDemandPlan';
      try {
        await this.$http.post(url, data);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable();
      } catch (e) {
        this.loading = false;
      }
    },
    /**
     * 计算商品总价
     * @param row
     */
    calculateTotalPrice(row) {
      let amount = 0;
      this.drawerConfig[1].tableData.forEach((i) => {
        amount += i.productTotalPrice;
      });
      this.setFormData(amount, 'amount');
    },

    /**
     * 删除商品
     * @param index {number || String}
     * @param configIndex {number}
     */
    deleteProduct(index, configIndex) {
      if (index === 'all') {
        this.drawerConfig[configIndex].tableData = [];
      } else {
        this.drawerConfig[configIndex].tableData.splice(index, 1);
      }
    },

    /**
     * 设置表单数据
     * @param data {}
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data {<void>}
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.forEach((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
        }
      });
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>}
     * @param state {boolean}
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ebeef5;
}
.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}
.slot-body {
  display: flex;
  div {
    display: flex;
    align-items: center;
    flex-grow: 2;
    span {
      flex-shrink: 0;
      margin-right: 5px;
    }
    input {
      width: 150px;
    }
  }
}
.slot-body1 {
  display: flex;
  width: 80%;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
      margin-right: 5px;
    }
    input {
      width: 100px;
    }
  }
}
</style>
